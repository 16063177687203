const SETTINGS = {
    HOME: 'https://api.demobuscaia.eita.org.br',
    METABASE: {
        URL: 'https://metabase.icv.eita.org.br',
        SECRET_KEY: '7f024ec188862416e97422de1493984960b409208b5eb5d194ca1b83983f1c06',
        EMAIL: 'dtygel@eita.org.br'
    }
};

export default SETTINGS;
