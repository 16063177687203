import React, { useEffect, useState } from 'react';
import { DataGridBySchemaEditable, useAPIWrapper } from 'drf-react-by-schema';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
} from '@mui/material';

import '../styles/globals.css';
import '../styles/home.css';

export default function Home() {
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ data: [], columns: [], schema: null });
    const [paginationModel, setPaginationModel] = useState(null);
    const [generatedAiText, setGeneratedAiText] = useState('');
    const [generatedAiTextOpen, setGeneratedAiTextOpen] = useState(false);
    /**
     * @type object
     */
    const { getGenericModelList, getRawData } = useAPIWrapper();

    const updateData = async () => {
        setLoading(true);
        const response = await getGenericModelList({
            model: 'busca',
            queryParams: query ? [`q=${query}`] : undefined,
            columnFields: ['title', 'description', 'content_type', 'keywords', 'themes', 'author'],
        });
        setLoading(false);
        setData(response);
    };

    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents form submission if inside a form
            updateData();
        }
    };

    const handleCloseGeneratedAiTextDialog = () => {
        setGeneratedAiText('');
        setGeneratedAiTextOpen(false);
    };

    const minWidthFields = {
        description: 400,
        keywords: 300,
    };

    const customColumnOperations = async (column) => {
        if (minWidthFields) {
            if (Object.prototype.hasOwnProperty.call(minWidthFields, column.field)) {
                column.minWidth = minWidthFields[column.field];
            }
        }
        return column;
    };

    const onSelectActions = [
        {
            title: 'Gerar síntese por IA',
            action: async (params) => {
                setLoading(true);
                const path = `ai_generator?${
                    params && params.length > 0
                        ? params.map((item) => `ids=${item.id}`).join('&')
                        : ''
                }`;
                const newGeneratedAiText = await getRawData(path);
                setLoading(false);
                setGeneratedAiText(newGeneratedAiText);
                setGeneratedAiTextOpen(true);
            },
        },
    ];

    useEffect(() => {
        updateData();
    }, []);

    useEffect(() => {
        if (
            paginationModel &&
            'filter' in paginationModel &&
            query !== paginationModel.filter.quickFilterValues[0]
        ) {
            setQuery(paginationModel.filter.quickFilterValues[0]);
        }
    }, [paginationModel]);

    return (
        <>
            <Box sx={{ backgroundColor: '#fff', p: 4 }}>
                <Typography variant="h5" sx={{ mb: 3 }}>
                    Demonstração de busca semântica para a Carnal da EITA em Búzios
                </Typography>
                <Box alignItems="center" sx={{ width: '100%', display: 'flex', pb: 2 }}>
                    <TextField
                        label="Busca"
                        variant="outlined"
                        value={query}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        sx={{ mx: 2 }}
                        fullWidth={true}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={updateData}
                        sx={{ marginRight: 2 }}>
                        Buscar
                    </Button>
                </Box>
                {data.schema && (
                    <Box sx={{ height: 600 }}>
                        <DataGridBySchemaEditable
                            data={data.data}
                            columns={data.columns}
                            customColumnOperations={customColumnOperations}
                            schema={data.schema}
                            model={'busca'}
                            loading={loading}
                            indexField={'title'}
                            // indexFieldBasePath={indexFieldBasePath}
                            // indexFieldViewBasePath={indexFieldViewBasePath}
                            isEditable={false}
                            hasBulkSelect={true}
                            onSelectActions={onSelectActions}
                            isAutoHeight={true}
                            // tableAutoHeight={tableAutoHeight}
                            hideFooterPagination={true}
                            hideFooterComponent={true}
                            hideToolbarComponent={false}
                            hideFilterButton={true}
                            hideQuickFilterBar={true}
                            paginationModel={paginationModel}
                            setPaginationModel={setPaginationModel}
                        />
                    </Box>
                )}
            </Box>
            <Dialog open={generatedAiTextOpen} onClose={handleCloseGeneratedAiTextDialog}>
                <DialogTitle>
                    Resumo feito pela IA sobre a seleção de conteúdos escolhidos
                </DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: generatedAiText }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseGeneratedAiTextDialog}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
