const PAGES = {
    home: {
        title: 'Início',
        home: true,
        skipRouter: true,
        permission: null,
    },
};

export default PAGES;
